import React from 'react';
import {Filter, Transaction} from "../types/transaction";
import {Badge, Descriptions, DescriptionsProps, Space, Typography} from 'antd';
import {formatDate, formatIBAN, formatNumber, getStatusBadge} from "../utils/transactionUtils";
import {Journals} from "./Journals";


type TransactionDetailsInlineProps = { transaction: Transaction, filter: Filter };

const getItems = (transaction: Transaction): DescriptionsProps['items'] => (
    [
        {
            key: 'id',
            label: 'Id',
            children: <Typography.Text copyable style={{textWrap: "nowrap"}}>{transaction.id}</Typography.Text>,
        },
        {
            key: 'description',
            label: 'Description',
            children: transaction.description,
            span: 2
        },
        {
            key: 'amount',
            label: 'Amount',
            children: <Typography.Text style={{color: transaction.amountValue > 0 ? "green" : "red"}}>{`${formatNumber(transaction.amountValue)} ${transaction.amountCurrency}`}</Typography.Text>,
        },
        {
            key: 'bookingDate',
            label: 'Booking Date',
            children: formatDate(transaction.bookingDate),
        },
        {
            key: 'valueDate',
            label: 'Value Date',
            children: formatDate(transaction.valueDate),
        },
        {
            key: 'creditor',
            label: 'Creditor',
            children: (
                <>
                    <Typography.Text>{`Name: ${transaction.creditorName}`}</Typography.Text>
                    <br/>
                    <Typography.Text>{`IBAN: ${formatIBAN(transaction.creditorIban)}`}</Typography.Text>
                    <br/>
                    <Typography.Text>{`BIC: ${transaction.creditorBic}`}</Typography.Text>
                </>
            ),
        },
        {
            key: 'debtor',
            label: 'Debtor',
            children: (
                <>
                    <Typography.Text>{`Name: ${transaction.debtorName}`}</Typography.Text>
                    <br/>
                    <Typography.Text>{`IBAN: ${formatIBAN(transaction.debtorIban)}`}</Typography.Text>
                    <br/>
                    <Typography.Text>{`BIC: ${transaction.debtorBic}`}</Typography.Text>
                </>
            ),
        },
        {
            key: 'reference',
            label: 'Details',
            children: (
                <>
                    <Typography.Text>{transaction.vault.name}</Typography.Text>
                    <br/>
                    <Typography.Text>{transaction.endToEndId}</Typography.Text>
                    <br/>
                    <Typography.Text>{transaction.mandateId}</Typography.Text>
                </>
            )
            ,
        },
        {
            key: 'status',
            label: 'Status',
            children: <Badge status={getStatusBadge(transaction.status.type)} text={transaction.status.name}/>,
            span: 3,
        },
    ]
);

export const TransactionDetailsInline: React.FC<TransactionDetailsInlineProps> = ({transaction, filter}) => {
    return <>
        <Descriptions size="small" bordered items={getItems(transaction)}/>
        <Journals transaction={transaction} filter={filter}/>
    </>
        ;
};