import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import './additional.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import {Transactions} from "./components/pages/Transactions";
import {Journals} from "./components/pages/Journals";
import Login from "./components/pages/Login";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {index: true, element: <Home/>},
//            {path: "transaction/:id", element: <User/>},
            {path: "transactions", element: <Transactions/>},
            {path: "journals", element: <Journals/>},
            {path: "login", element: <Login/>},
            {path: "*", element: <NotFound/>}
        ]
    }
]);


ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
            <RouterProvider router={router}/>
    </React.StrictMode>,
);
