import {Divider, Typography} from "antd";

/**
 * Creates a page with a title, description, and optional divider. Any children will be placed below.
 * @param {Object} props Props object, which is destructured.
 * @param {string} props.title The title.
 * @param {string} props.description The description.
 * @param {boolean} [props.divider=true] Add a divider below description. Defaults to true.
 */
function Page(props: { title: string, description: string, divider: boolean, children?: any }) {
    let {title, description, divider, children} = props;
    return (
        <>
            {children}
        </>
    );
}

Page.defaultProps = {divider: true}

export default Page;