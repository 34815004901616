import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_GATEWAY_URL || 'https://desc.team'}/finance/api/graphql`
});

const cache = new InMemoryCache({
    typePolicies: {
        Journal: {
            keyFields: ["id"],
        },
    },
});

const client = new ApolloClient({
    link: httpLink,
    cache: cache,
});

export default client;