import React, {useRef, useState} from 'react';
import Page from "./Page";
import {useQuery} from '@apollo/client';
import {SearchOutlined} from '@ant-design/icons';
import {Button, DatePicker, Divider, Input, InputRef, Space, Table, TableColumnType, TimeRangePickerProps, Typography} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import type {FilterDropdownProps} from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import {Account, Filter, Journal, Source} from "../../types/transaction";
import type {ExpandableConfig} from "rc-table/lib/interface";
import {clientDateFormat, formatDate, formatNumber, getSumJournals, serverDateFormat} from "../../utils/transactionUtils";
import {GET_JOURNAL_BY_FILTER} from "../../queries/transaction";
import {TransactionDetailsInline} from "../TransactionDetailsInline";
import {AccountSelect} from "../AccountSelect";
import {SourceSelect} from "../SourceSelect";
import {exportJournalsCSV} from "../../utils/export";

const {Column} = Table;
const {RangePicker} = DatePicker;

type DataIndex = keyof Journal;

const statusColors = new Map<string, string>([
  ["unassign", "default"],
  ["pending", "default"],
  ["booked", "processing"],
  ["processing", "warning"],
  ["accounted", "success"],
  ["error", "error"],
  ["closed", "success"]
]);

const rangePresets: TimeRangePickerProps['presets'] = [
  {label: 'Last year', value: [dayjs().startOf('year').add(-1, 'y'), dayjs().endOf('year').add(-1, 'y')]},
  {label: 'Actual year', value: [dayjs().startOf('year'), dayjs()]},
  {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
  {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
];

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
const parseDateRange =
  (dates: RangeValue<Dayjs>, defaultFilter: Filter): Filter => (
    {
      startDate: dates?.[0]?.format(serverDateFormat) || defaultFilter.startDate,
      endDate: dates?.[1]?.format(serverDateFormat) || defaultFilter.endDate,
      accounts: defaultFilter.accounts,
      sources: defaultFilter.sources,
    });

export const Journals: React.FC = () => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [expandRowKeys, setExpandRowKeys] = useState<readonly React.Key[]>([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filter, setFilter] = useState<Filter>(
    {
      startDate: dayjs().startOf('month').format(serverDateFormat),
      endDate: dayjs().endOf('month').format(serverDateFormat),
      accounts: [],
      sources: []
    });
  const searchInput = useRef<InputRef>(null);

  const {loading, error, data} = useQuery(GET_JOURNAL_BY_FILTER, {
    variables: {filter: filter},
    pollInterval: 10000
  });


  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string,
  ): boolean => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    return true;
  };

  const handleReset = (clearFilters: () => void): boolean => {
    clearFilters();
    setSearchText('');
    return true;
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<Journal> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters) && handleSearch([''], confirm, dataIndex)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
    ),
    onFilter: (value, record: Journal) =>
      record[dataIndex]?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) || false,
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const expandable: ExpandableConfig<Journal> = {
    expandRowByClick: true,
    onExpand: (expanded, record) => setExpandRowKeys(expanded && record.id ? [record.id] : []),
    expandedRowRender: (record) => (record.transaction ? <TransactionDetailsInline transaction={record.transaction} filter={filter}/> : <div>none</div>),
    expandedRowKeys: expandRowKeys,
    rowExpandable: () => true,
  };

  const handleAccountChange = (value: any) => {
    setFilter(filter => ({...filter, accounts: value}));
  };

  const handleSourceChange = (value: any) => {
    setFilter(filter => ({...filter, sources: value}));
  };

  const handleDateRangeChange = (dates: RangeValue<Dayjs>, dateString: [string, string]) => setFilter(filter => parseDateRange(dates, filter))
  const sumJournals = getSumJournals(data?.journalsByFilter);

  const handleExport = () => {
    exportJournalsCSV(data?.journalsByFilter)
  };


  return (
    <Page title="Journals" description="" divider={true}
    >
      <Table<Journal>
        loading={loading}
        dataSource={data?.journalsByFilter}
        rowKey="id"
        //rowSelection={rowSelection}
        pagination={{position: ["topRight", "bottomRight"], defaultPageSize: 50}}
        size="small"
        expandable={expandable}
        title={_ => <Space>
          <RangePicker
            presets={rangePresets}
            value={[dayjs(filter.startDate, serverDateFormat), dayjs(filter.endDate, serverDateFormat)]}
            onChange={handleDateRangeChange}
            format={clientDateFormat}
          />
          <Divider orientation="center"/>
          <AccountSelect mode="multiple" style={{width: 520}} onChange={handleAccountChange}/>
          <Divider orientation="center"/>
          <SourceSelect mode="multiple" style={{width: 420}} onChange={handleSourceChange}/>
          <Divider orientation="center"/>
          <Button onClick={handleExport}>Export</Button>
        </Space>}
        summary={(pageData) => {
          const sum = getSumJournals(pageData);
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align={"right"}>
                  <Typography.Text style={{color: sum > 0 ? "green" : "red"}}>{formatNumber(sum)}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align={"right"}>
                  <Typography.Text style={{color: sumJournals > 0 ? "green" : "red"}}>{formatNumber(sumJournals)}</Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }
        }
      >
        <Column
          title="Id. Nr."
          dataIndex="id"
          key="id"
          width={100}
          sorter={(a: Journal, b: Journal) => (a.id || 0) - (b.id || 0)}
          {...getColumnSearchProps("id")}
          render={(value: string) => (
            <Typography.Text>{`FIN-${value}`}</Typography.Text>
          )}
          onFilter={(value, record: Journal) =>
            (record.id || 0).toString()
              .toLowerCase()
              .includes((value as string).toLowerCase().replaceAll("fin-", ""))}

        />
        <Column
          title="Date"
          dataIndex="valueDate"
          key="valueDate"
          width={100}
          render={(value: string) => (
            <Typography.Text>{formatDate(value)}</Typography.Text>
          )}
          sorter={(a: Journal, b: Journal) => a.valueDate.localeCompare(b.valueDate)}
        />
        <Column
          title="Name"
          dataIndex="peerName"
          key="peerName"
          width={300}
          {...getColumnSearchProps("peerName")}
          render={(_: any, record: Journal) => (
            <Highlighter
              highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={record.peerName}
            />
          )}
          onFilter={(value, record: Journal) =>
            record.peerName
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())}

        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          {...getColumnSearchProps("description")}
        />
        <Column
          title="Account"
          dataIndex="account"
          key="account"
          width={350}
          render={(value: Account) => (
            <Typography.Text>{value?.fullName || "Account is not defined"}</Typography.Text>
          )}
        />
        <Column
          title="Source"
          dataIndex="source"
          key="source"
          width={250}
          render={(value: Source) => (
            <Typography.Text>{value ? `${value.key} ${value.name}` : "Source is not defined"}</Typography.Text>
          )}
        />
        <Column
          title="Amount"
          dataIndex="amountValue"
          key="amountValue"
          width={100}
          className="align-right"
          render={(amount: number) => (
            <Typography.Text style={{color: amount > 0 ? "green" : "red"}}>{formatNumber(amount)}</Typography.Text>
          )}

        />
        <Column title="Currency" dataIndex="amountCurrency" key="amountCurrency" width={100}/>
      </Table>
    </Page>
  );
};