import React from 'react';
import {Select, SelectProps} from 'antd';
import {useQuery} from "@apollo/client";
import {GET_ACCOUNTS} from "../queries/transaction";
import {Account} from "../types/transaction";

export interface AccountSelectProps<ValueType = string>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
}

const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const AccountSelect = <ValueType extends { key?: string; label: string; value: string | number } = any, >({...props}: AccountSelectProps<ValueType>) => {
  const {loading, error, data} = useQuery(GET_ACCOUNTS);

  return (
    <Select<any, { label: string; value: string }>
      mode={props.mode}
      showSearch
      autoFocus
      defaultOpen={props.defaultOpen}
      defaultValue={props.value}
      placeholder={props.placeholder}
      placement="bottomRight"
      defaultActiveFirstOption={false}
      suffixIcon={null}
      optionFilterProp="children"
      filterOption={filterOption}
      notFoundContent={null}
      {...props}
      options={(data?.accounts || []).map((d: Account) => ({
        value: d.id,
        label: d.fullName,
      }))}
    />
  );

};

